import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import PaymentResume from '../PaymentResume';
import paymentCheck from '../../../assets/icons/payment-check.svg';

import './styles.scss';
import {getOrder, getOrderByCart, getStatusOrder} from "../../../services/services";
import {Order} from "../../../ts/interfaces/Order";
import {formatMoneyMask} from "../../../helper/FormatFields";
import {toast} from "react-toastify";
import Preloader from "../../../component/Preloader";
import TagManager from "react-gtm-module";
import Ebit from "../../../component/Ebit";
import {Cookies} from "react-cookie";

export default function PaymentFinish() {

  document.title = 'Compra foi concluída com sucesso.';

  const navigate = useNavigate();
  const {search} = useLocation();

  const [order, setOrder] = useState<Order>();
  const [order2, setOrder2] = useState<Order>();
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    setLoading(true);
    getOrderByCart().then(result => {
      setOrder(result.data.order);
      setLoading(false);
    })
  }, [navigate]);

  useEffect(() => {
    const orderId = new URLSearchParams(search).get('order_id1');
    if (orderId) {
      getOrder(orderId).then(result => {
        setOrder2(result.data.order);
      })
    }
  }, [search]);

  useEffect(() => {
    if (order && order.payment
      && order.payment.method === "pagarme_boleto"
      && order.payment.boleto_url) {
      window.open(order.payment.boleto_url, '_blank', 'toolbar=yes,menubar=yes,resizable=yes,status=no,scrollbars=yes,width=900,height=485');
    }

    if (order) {
      TagManager.dataLayer({
        dataLayer: {
          event: "ee_transaction",
          city: order?.city,
          ecommerce: {
            purchase: {
              actionField: {
                affiliation: "Nova Concursos",
                coupon: order.coupon,
                id: order.increment_id,
                revenue: order.shipping_price? (order.grand_total - order.shipping_price) : order.grand_total,
                shipping: order.shipping_price,
                tax: 0
              },
              products: order.items.map((item) => {
                return {
                  brand: "Nova Concursos",
                  category: item.type + "/" + item.name,
                  id: item.sku,
                  image: item.path_image,
                  name: item.name,
                  price: item.price - item.discount_amount,
                  quantity: item.qty,
                  variant: item.type
                }
              })
            },
          },
          email: order.customer_email,
          name: order.customer_name,
          state: order?.state,
          externalCheckoutId: order.cart_session_id,
          product_ids: order.items.map((item) => {
            return item.sku
          }),
          em: order.customer_email_hash256,
          ph: order.customer_ph_hash256,
          fn: order.customer_fn_hash256,
          ln: order.customer_ln_hash256,
          customer_email: order.customer_email,
          customer_ph: order.customer_ph,
          customer_fn: order.customer_fn,
          customer_ln: order.customer_ln,
          affiliate_fb_pixel: cookies.get('affiliateplus_pixel_id'),
          affiliate_go_id: cookies.get('affiliateplus_conversion_id'),
          affiliate_go_label: cookies.get('affiliateplus_conversion_label')
        }
      });
    }

  }, [order]);

  async function copyPix() {
    if (order && navigator.clipboard) {
      navigator.clipboard.writeText(order.payment.pix_qr_code);
    }
    toast.success("PIX copiado com sucesso!")
  }

  useEffect(() => {
    if (order && (order.payment.method === "pagarme_pix" || order.payment.method === "mercadopago_pix")) {
      getStatusOrderPix(order.increment_id, 0)
    }
  }, [order]);

  const getStatusOrderPix = (incrementId: string, count: number) => {
    if (count < 60) {
      getStatusOrder(incrementId).then(result => {
        if (result?.data?.success) {
          if (result.data.status !== "paid") {
            setTimeout(() => {
              getStatusOrderPix(incrementId, count + 1)
            }, 5000)
          } else {
            if (result.data.url_redirect_upsell) {
              window.location.href = result.data.url_redirect_upsell;
            } else {
              navigate('/pagamento-pix/finalizado')
            }
          }
        }
      })
    }
  }

  return (<>
      <Preloader isVisible={loading}/>

      {order2 ? <div className='payment-finish-container'>
        <div className='payment-finish-content'>
          <img src={paymentCheck} alt="payment check"/>
          <h2 className='description'>Parabéns! Sua compra foi concluída com sucesso.</h2>
          <div className='numeroPedido'>
            <span>O número do seu pedido é:</span>
            <p className='orderNumber'>{order2?.increment_id} </p>
          </div>
          <p>Você receberá um e-mail de confirmação com os detalhes de seu pedido e um link para acompanhar seu
            progresso.</p>
        </div>
        <div className="payment-method">
          <p>Forma de pagamento</p>

          {(order2.payment.method === "pagarme_cc" || order2.payment.method === "mercadopago_cc") &&
            <div className="cartao-de-credito">
              <span><strong>Cartão de crédito</strong></span>
              <span>{order2.payment.installments}x de {formatMoneyMask(order2.payment.installment_amount)}</span>
            </div>}

          <a target="_blank" rel="nofollow"
             href={process.env.REACT_APP_AREA_CLIENTE_URL + "/?from=checkout&redirect=#/login"}
             className="acessar-area-aluno">Acessar Área do Aluno</a>
        </div>

        <PaymentResume
          order={order2}
        />
      </div> : <p></p>}

      {order ? <div className='payment-finish-container'>
        <div className='payment-finish-content'>
          <img src={paymentCheck} alt="payment check"/>
          <h2 className='description'>Parabéns! Sua compra foi concluída com sucesso.</h2>
          <div className='numeroPedido'>
            <span>O número do seu pedido é:</span>
            <p className='orderNumber'>{order?.increment_id} </p>
          </div>
          <p>Você receberá um e-mail de confirmação com os detalhes de seu pedido e um link para acompanhar seu
            progresso.</p>
        </div>
        <div className="payment-method">
          <p>Forma de pagamento</p>

          {(order.payment.method === "pagarme_cc" || order.payment.method === "mercadopago_cc") &&
            <div className="cartao-de-credito">
              <span><strong>Cartão de crédito {order.payment.type === "recurring" ? "(Recorrente)" : ""}</strong></span>
              <span>{order.payment.installments}x de {formatMoneyMask(order.payment.installment_amount)}</span>
            </div>}
          {(order.payment.method === "pagarme_pix" || order.payment.method === "mercadopago_pix") &&
            <div className="pix">
              <span><strong>PIX</strong></span>
              <div className="pix-qrcode" dangerouslySetInnerHTML={{__html: order.payment.pix_qr_code_html}}></div>
              <p><strong>Clique no campo para copiar o código do PIX (Chave Aleatória)</strong></p>
              <div className="pix-copy">
                <input onClick={() => copyPix()} type="button" value={order.payment.pix_qr_code}></input>
                <button onClick={() => copyPix()}>
                  <i className="file-copy"></i>
                </button>
              </div>
            </div>}
          {(order.payment.method === "pagarme_boleto" || order.payment.method === "mercadopago_boleto") &&
            <div className="boleto">
              <span><strong>Boleto</strong></span>
              <a className="boleto-btn" target="_blank" rel="nofollow" href={order.payment.boleto_url}><i
                className="icon-boleto"></i>Imprimir Boleto</a>
            </div>}

          {order.payment.method === "pagaleve_pix_parcelado" && <div className="cartao-de-credito">
            <span><strong>Pix Parcelado</strong></span>
          </div>}

          <a target="_blank" rel="nofollow"
             href={process.env.REACT_APP_AREA_CLIENTE_URL + "/?from=checkout&redirect=#/login"}
             className="acessar-area-aluno">Acessar Área do Aluno</a>
        </div>
        <Ebit order={order}/>
        <PaymentResume
          order={order}
        />
      </div> : <p></p>}
    </>
  )
}
